<script>
import { onMounted, ref } from 'vue'
import { http } from '@/http'
import { Dialog } from 'vant'
import { useRoute } from 'vue-router'

export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  setup() {
    let route = useRoute()
    let pageError = ref(null)
    let isAliPay = /(?:AlipayClient|AlipayApp)/i.test(navigator.userAgent)
    onMounted(() => {
      if(isAliPay) return
      window.localStorage.removeItem('userAuth')
      http.post('/?cmd=com.xqxc.api.pigear.biz.account.BrokerLoginService.checkRefId', {
        id: Number(route.query.referrerId)
      }).then(res => {
        if(!res.result) {
          pageError.value = false
        }
        pageError.value = res.result
      }).catch(err => {
        return false
      })
    })
    return {
      pageError,
      isAliPay
    }
  }
}
</script>
<template>
  <div>
    <van-dialog title="提示" :show-confirm-button="false" v-model:show="isAliPay">
      <p style="text-align: center; line-height: 1.5; font-size: .28rem; padding:.5rem">
        不支持支付宝打开，请使用微信或手机自带浏览器打开
      </p>
    </van-dialog>
    <template v-if="!isAliPay">
      <div class="page" v-if="pageError">
        <div class="box-ct">
          <span class="icon-broker">
            <img src="@/assets/images/icon-broker.png" alt="">
          </span>
          <p style="padding-top:1rem">尾号{{$route.query.referrerTel && $route.query.referrerTel.slice(-4)}}</p>
          <p style="margin-top:.58rem; font-size:.36rem">邀请你成为猪耳朵渠道商</p>
          <button @click="$router.push(`/channel-signup?referrerId=${$route.query.referrerId || ''}&referrerRole=${$route.query.referrerRole || ''}&referrerTel=${$route.query.referrerTel || ''}`)">立即加入</button>
        </div>
      </div>
      <p v-if="pageError !== null && !pageError" style=" line-height:100vh;text-align: center;color:#F56C6C">
        链接地址参数错误，无法正常打开页面
      </p>
    </template>
  </div>
</template>
<style scoped>
    .page { 
      box-sizing: border-box; 
      padding-top: 5rem;
      position: absolute; 
      top: 0; 
      left: 0; 
      right: 0; 
      bottom: 0; 
      background-repeat: no-repeat; 
      background-size: 100%; 
      background-image: url("~@/assets/images/bkgd3.png"); 
      background-color: #f52a1e
    }
    .box-ct {
      position: relative;
      text-align:center;
      border-radius: .2rem;
      margin: 0 auto;
      width: 6.9rem;
      height: 5.27rem;
      background: #fff;
    }
    .box-ct .icon-broker {
      position: absolute;
      width: 1.27rem;
      height: 1.27rem;
      left: 50%;
      top: -.56rem;
      margin-left: -.635rem;
    }
    .box-ct button {
      border: none;
      display: block;
      width: 5.29rem;
      height: 1.07rem;
      margin: 1.39rem auto 0;
      font-size: .36rem;
      font-weight: bold;
      color: #E22723;
      background-color: transparent;
      background-size: 100%;
      background-image: url("~@/assets/images/btn-bg3.png")
    }
</style>